import React from "react";
const Puff = ({ children, icon, heading, top }) => {
  return (
    <div
      className="puff"
      style={{
        marginTop: top && "96px",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "16px",
          marginBottom: "16px",
        }}
      >
        {icon}
        <span className="h3">{heading}</span>
      </div>
      {children}
    </div>
  );
};

export default Puff;
